<!-- 新增现货仓储 -->
<template>
    <div class="content-panel">
        <el-row :gutter="10" type="flex" justify="space-between" align="middle" class="breadcrumb-wrap">
            <el-col :span="20">
                <el-breadcrumb separator="/">
                    <el-breadcrumb-item :to="{ path: '/' }">{{msg('首页')}}</el-breadcrumb-item>
                    <el-breadcrumb-item :to="{ path: '/admin/stock'}">{{msg('库存管理')}}</el-breadcrumb-item>
                    <el-breadcrumb-item>{{msg('新增现货仓储')}}</el-breadcrumb-item>
                </el-breadcrumb>
            </el-col>
        </el-row>
        <el-row :gutter="10"  class="ctrl-wrap">
        <el-form ref="form" :model="stock" label-width="100px" style="margin:0 20px">
            <h3>必填项</h3>
            <el-row>
                <el-col :xs="24" :sm="8">
                    <el-form-item :label="msg('收件仓库')">
                        <el-select v-model="stock.receiver"  placeholder="请选择" size="small">
                            <el-option
                                v-for="item in stores"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.name">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="8">
                    <el-form-item :label="msg('配送方式')">
                        <el-select v-model="stock.expCompany"  placeholder="请选择" size="small">
                            <el-option
                                v-for="item in dicts.transformType"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="8">
                    <el-form-item :label="msg('运输方式')">
                        <el-select v-model="stock.transport"  placeholder="供参考，实际以出货为准" size="small">
                            <el-option
                                v-for="item in dicts.transportTypeInout"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <!-- 新增现货仓储选填项 -->
            <h3>选填项</h3>
            <el-row>
                <el-col :xs="24" :sm="8">
                    <el-form-item :label="msg('包号')">
                        <el-input v-model.trim="stock.orderBagCode" :placeholder="msg('可填可不填，不填由系统生成')" size="small"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="8">
                    <el-form-item :label="msg('货物类型')">
                        <el-select v-model="stock.expGoodType" style="margin-bottom:5px;"
                            clearable placeholder="选择货物类型" size="small">
                            <el-option
                                v-for="item in this.dicts.stockExpGoodType"
                                :key="item.value" 
                                :label="item.label"
                                :value="item.value"> 
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="8">
                    <el-form-item :label="msg('金额')">
                        <el-input-number v-model="stock.amtOrder" :min="0" :precision="2" size="small"></el-input-number>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :xs="24" :sm="8">
                    <el-form-item :label="msg('报单备注')">
                        <el-input v-model.trim="stock.remark1" :placeholder="msg('请输入报单备注')" size="small"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="8">
                    <el-form-item :label="msg('货单备注')">
                        <el-input v-model.trim="stock.remark2" :placeholder="msg('请输入货单备注')" size="small"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <!-- end -->
            <h3>库存</h3>
            <el-table :data="stock.stockDetails" style="width: 100%;" size="mini" ref="dataTable" stripe>
                <el-table-column type="index" width="50"></el-table-column>
                <el-table-column :label="msg('订单编号')" min-width="120" :show-overflow-tooltip="true">
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.code" placeholder="请输入订单编号" size="mini" ></el-input>
                    </template>
                </el-table-column>
                <el-table-column :label="msg('标题')" min-width="120" :show-overflow-tooltip="true">
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.productTitle" placeholder="请输入标题" size="mini" ></el-input>
                    </template>
                </el-table-column>
                <el-table-column :label="msg('规格')" min-width="100" >
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.productSpec" placeholder="请输入规格" size="mini" ></el-input>
                    </template>
                </el-table-column>
                <el-table-column :label="msg('件数')" min-width="90" >
                    <template slot-scope="scope">
                        <el-input-number v-model="scope.row.productQty" :min="1" size="mini"></el-input-number>
                    </template>
                </el-table-column>
                <el-table-column :label="msg('SKU')" min-width="100" >
                    <template slot-scope="scope">
                        <el-input v-model.trim="scope.row.sku" size="mini" ></el-input>
                    </template>
                </el-table-column>
                <el-table-column :label="msg('货号')" min-width="100" >
                    <template slot-scope="scope">
                        <el-input v-model.trim="scope.row.variationSku" size="mini" ></el-input>
                    </template>
                </el-table-column>
                <el-table-column label="操作" min-width="80">
                    <template slot-scope="scope">
                        <el-button type="danger" class="nopad" icon="el-icon-delete" size="mini" plain
                            @click.stop="stock.stockDetails.splice(scope.$index, 1)">
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div style="text-align:center; margin-top:5px;">
                <el-button type="primary" size="mini" plain @click="addDetails()">
                    <i class="el-icon-circle-plus-outline"></i>添加更多
                </el-button>
            </div>
        </el-form>

        <div style="text-align:center; margin-top: 15px;">
            <el-button type="primary" size="mini"  @click="onSubmit(1)"><i class="el-icon-check"></i><span>{{msg('确认新增')}}</span></el-button>
            <el-button type="primary" size="mini"  @click="onSubmit(2)"><i class="el-icon-check"></i><span>{{msg('新增并打印现货单')}}</span></el-button>
            <el-button type="primary" size="mini"  @click="doPrint()"><i class="el-icon-check"></i><span>{{msg('打印')}}</span></el-button>
        </div>

        </el-row>

        <span>
            <vue-easy-print tableShow ref="easyPrint">
                <template>
                    <print-tpl :ticketDtos="ticketDtos" :bagDto="bagDto" ></print-tpl>
                </template>
            </vue-easy-print>
        </span>

    </div>
</template>

<script>
import vueEasyPrint from "vue-easy-print";
import PrintTpl from "./StockPrintStoreTpl.vue";
export default {
    name: "StockAddStore",
    components:{
        PrintTpl : PrintTpl,
        vueEasyPrint: vueEasyPrint,
    },
    data(){
        return {
            stores: null,
            ticketDtos:[],
            bagDto:null,
            stock: {
                receiver:null,
                expCompany:null,
                transport:null,
                orderBagCode:null,
                expGoodType:null,
                amtOrder:0,
                remark1:null,
                remark2:null,
                stockDetails:[{
                        code:null,
                        productTitle:null,
                        productSpec:null,
                        productQty:1,
                        sku:null,
                        variationSku:null,
                    }],
            },
        }
    },
    mounted(){
        this.getStores();
        this.initCode();
    },
    watch:{
      $route(){
        //跳转到该页面后需要进行的操作
        this.getStores();
        this.initCode();
        this.ticketDtos = [];
        this.bagDto = null;
      }
    },
    methods:{
        init(){
            this.stock = {
                receiver:null,
                expCompany:null,
                transport:null,
                orderBagCode:null,
                expGoodType:null,
                amtOrder:0,
                remark1:null,
                remark2:null,
                stockDetails:[{
                        code:null,
                        productTitle:null,
                        productSpec:null,
                        productQty:1,
                        sku:null,
                        variationSku:null,
                    }],
            }
        },
        initCode(){
            this.$axios.get(this.$kit.api.stock.initCode)
                .then((result)=> {
                    if(result.status){
                        let data = result.data;
                        this.stock.orderBagCode = data.bagCode;
                        this.stock.stockDetails[0].code = data.code;
                    }
                });
        },
        getStores(){
            this.$axios.post(this.$kit.api.store.listUsed)
                .then((result)=> {
                    if(result.status){
                        this.stores = result.data;
                    }
                });
        },
        onSubmit(type){
            var _this = this;
            if(!this.stock.receiver){
                this.$message(this.msg('收件人不能为空'));
                return;
            }
            if(!this.stock.orderBagCode){
                this.$message(this.msg('包号不能为空'));
                return;
            }
            if(this.stock.stockDetails.length <= 0){
                this.$message(this.msg('库存不能为空'));
                return;
            }
            if(!this.stock.expCompany){
                this.$message(this.msg('配送方式不能为空'));
                return;
            }
            if(!this.stock.transport){
                this.$message(this.msg('运输方式不能为空'));
                return;
            }
            let isAll = true;
            this.stock.stockDetails.forEach(e => {
                if(!e.code || !e.productTitle || !e.productSpec || e.productQty <= 0){
                    isAll = false;
                }
            })
            if(!isAll){
                this.$message(this.msg('库存标题和规格不能为空'));
                return;
            }

            //判断货单号不能重复
            let codes = this.stock.stockDetails.map(e => e.code).join(',');
            _this.$axios.post(_this.$kit.api.stock.checkCodes,codes)
                .then(function(result){
                    if(result.status){  
                        _this.$axios.post(_this.$kit.api.stock.createStore+'?type='+type,_this.stock)
                            .then(function(result){
                                if(result.status){
                                    // _this.$message(_this.msg('添加成功'));
                                     _this.$alert(_this.msg(result.msg), _this.msg('提示'), {
                                        confirmButtonText: '确定',
                                        callback: () => {
                                            _this.init();
                                            _this.initCode();
                                            if(type == 2){
                                                _this.ticketDtos = result.data.dtos;
                                                _this.bagDto = result.data.bagDto;
                                                _this.doPrint();
                                            }
                                        }
                                    });
                                }else{
                                    // _this.$message(_this.msg('添加失败'));
                                    _this.$alert(_this.msg(result.msg), _this.msg('提示'), {
                                        confirmButtonText: '确定',
                                    });
                                }
                            });
                    }else{
                        _this.$message(_this.msg('单号重复'));
                    }
                });
        },
        addDetails(){
            this.$axios.get(this.$kit.api.stock.createCode)
                .then((result)=> {
                    if(result.status){
                        let data = result.data;
                        var detail = {
                            code:data,
                            productTitle:'',
                            productSpec:'',
                            productQty:1,
                            sku:null,
                            variationSku:null,
                            remark:'',
                        }
                        this.stock.stockDetails.push(detail);
                    }
                });
        },
        doPrint(){
            let _this = this;
            _this.$nextTick(()=>{
                _this.$refs.easyPrint.$nextTick(() => {
                    setTimeout(()=>{
                        _this.$refs.easyPrint.print();
                    }, 1000);
                });
            });
        },
    }

}
</script>

<style lang="scss" scoped>
.xmain .router-tab-page {
    height: calc(100vh - 100px);
}
</style>