<!--打印现货单模板-->
<template>
  <div class="print-box" 
    style="block"
  >
    <div class="tag-wrap" v-if="bagDto">
        <h2>包裹單號</h2>
        <img class="tag-barcode" :src="'data:image/png;base64,'+bagDto.barcodeAsBase64" />
        <div class="tag-code">{{bagDto.pkgCode.substring(0, bagDto.pkgCode.length-4)}}
          <span style="font-size: 22px; font-weight:bold;">{{bagDto.pkgCode.substring(bagDto.pkgCode.length-4)}}</span>
        </div>
        <div class="tag-total" style="display:flex; align-items:center; justify-content:center;">
          <span style="display:inline-block; margin-right:20px; font-size:22px; font-weight:bold;">{{bagDto.total}}</span>
          {{bagDto.created}}
        </div>
    </div>
    
    <div class="next-page"></div>
    <div class="print-wrap" v-for="(ticketDto, index) in ticketDtos" :key="index">

      <div class="invoice-box">
        <div class="invoice-header">
          <div
            class="barcode-warp"
          >
            <img :src="'data:image/png;base64,'+ticketDto.barcodeAsBase64" />
            <div style="margin-top:5px;">{{ticketDto.code?ticketDto.code:''}}</div>
          </div>
        </div>
        <div class="invoice-header1" style="text-align:right">
            <div style="font-size: 30px; font-weight:bold;">{{ticketDto.code.substring(ticketDto.code.length-4)}}</div>
          </div>
        <div class="dotted-line"></div>
        <div class="xtable-wrap">
            <table class="orders">
              <!--[快递单号，货架号，件数，品名，商品名，规格]-->
              <template>
                <tr>
                  <td colspan="3" class="td-name">
                    <span style="font-weight:bold;font-size:15px">库存编号：</span>{{ticketDto.specCode}}
                  </td>
                </tr>
                <tr>
                  <td colspan="3" class="td-name">
                    <span style="font-weight:bold;font-size:15px">描述：</span>{{ticketDto.title}}
                  </td>
                </tr>
                <tr>
                  <td colspan="3" class="td-spec" style="padding: 5px 0;">
                    <span style="font-weight:bold;font-size:15px">規格：</span>{{ticketDto.spec}}<span>&nbsp;&nbsp;&nbsp;&nbsp;<span style="font-weight:bold;">///&nbsp;&nbsp;&nbsp;&nbsp;件數：</span><span style="font-size:20px"><strong>{{ticketDto.qty}}</strong></span></span>
                  </td>
                </tr>
              </template>
            </table>
        </div>
        <!--end orders-->
      <!--end invoice-box-->
      <div class="next-page" v-if="ticketDtos && ticketDtos.length > 1"></div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StockPrintPkgTicketTpl",
  props: {
    ticketDtos: Array,
    bagDto:Object,
  },
  computed: {
  },
  methods:{
  }
};
</script>

<style lang="scss" scoped>

table{
  border-bottom: 1px solid #999;
  border-right: 1px solid #999;
}

table td, table th{
  border-left: 1px solid #999;
  border-top: 1px solid #999;
}

@media print{
  table{
    border-bottom: 1px solid #000;
    border-right: 1px solid #000;
  }

  table td, table th{
    border-left: 1px solid #000;
    border-top: 1px solid #000;
  }
}

.print-box {
  width: 100%;
  margin-top: 10px;
}
.invoice-box{
  -webkit-print-color-adjust: exact;
}
.print-wrap {
  margin: 0px auto;
  width: 400px;
}
.invoice-header {
  position: relative;
  text-align: center;
  line-height: 1;
  padding-bottom: 10px;
}
.invoice-header .goodtype1 {
  width: 30px;
  height: 30px;
  font-size: 30px;
  position: absolute;
  left: 1px;
  top: 7px;
  line-height: 1;
  padding: 1px;
  border: 2px solid;
}
.invoice-header .goodtype0 {
  width: 30px;
  height: 30px;
  font-size: 30px;
  position: absolute;
  left: 1px;
  top: 7px;
  line-height: 1;
  border: 2px solid;
  border-radius: 50%;
}
.invoice-header .offline1 {
  color: white;
  background: black;
}

.invoice-header .company {
  font-size: 30px;
  position: absolute;
  left: 40px;
  top: 10px;
  line-height: 1;
}
.dotted-line {
  border-bottom: 2px dashed #000;
}
.print-wrap h3 {
  margin: 6px 0px;
  text-align: center;
  font-size: 16px;
}
.line-row {
  display: flex;
  font-size: 13px;
  color: #333;
  flex-wrap: nowrap;
}
.line-row .inline {
  flex: 0 0 50%;
}

.xline {
  font-size: 13px;
  display: flex;
  color: #333;
  flex-wrap: nowrap;
}

.xtable-wrap th {
  font-size: 14px;
  color: #333;
}
.xtable-wrap table {
  width: 100%;
  border-spacing: 0px;
  margin: 10px 0px;
}
.xtable-wrap td {
  font-size: 13px;
  padding-left: 3px;
  padding-right: 3px;
  color: #333;
}
.next-page {
  page-break-after: always;
}
.print-wrap h3 {
  margin: 6px 0px;
  text-align: center;
  font-size: 16px;
}
.tag-code{
  font-size: 13px;
  color: #333;
  margin:5px 0;
}
.tag-total{
  font-size: 16px;
  color: #333;
  margin:15px 0;
}
.tag-name{
  font-size: 20px;
  color: #333;
  margin:10px 0;
}

.tag-wrap {
  text-align: center;
  line-height: 1;
  font-size: 13px;
  color: #333;
}
.product-img{
  width:100%;
}
</style>
